import clsx from 'clsx';
import { ButtonHTMLAttributes, FC } from 'react';

import styles from './index.module.scss';

// eslint-disable-next-line @typescript-eslint/ban-types
type ITouchable = ButtonHTMLAttributes<{}> & { mix?: string };

export const Touchable: FC<ITouchable> = ({ children, mix, ...props }) => (
	<button type="button" className={clsx(styles.button, mix)} {...props}>
		{children}
	</button>
);
