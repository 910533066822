import { FC, ReactElement, ReactNode, useEffect, useState } from 'react';

interface IProps {
	children: ReactNode;
}

export const Desktop: FC<IProps> = ({ children }) => {
	const [visible, setVisible] = useState(false);

	const calculate = (): void => {
		const current = window.innerWidth >= 992;
		setVisible(current);
	};

	useEffect(() => {
		calculate();
		window.addEventListener('resize', calculate);
		return () => {
			window.removeEventListener('resize', calculate);
		};
	}, []);

	return visible ? (children as ReactElement) : null;
};
