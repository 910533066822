import clsx from "clsx";
import { FC } from "react";
import { Icon } from "ui-kit-euroopt";
import { TSizes } from "ui-kit-euroopt/dist/interfaces";
import { Touchable } from "../touchable";

import styles from "./index.module.scss";
interface IArrow {
  direction: "left" | "right";
  disabled?: boolean;
  size?: TSizes;
  onClick?: () => unknown;
  mix?: string;
}

export const Arrow: FC<IArrow> = ({
  direction = "left",
  size = "medium",
  disabled,
  onClick,
  mix,
}) => (
  <Touchable
    disabled={disabled}
    onClick={onClick}
    mix={clsx(styles.arrow, mix, {
      [styles.arrow_direction_left]: direction === "left",
      [styles.arrow_direction_right]: direction === "right",
      [styles.arrow_size_medium]: size === "medium",
      [styles.arrow_size_small]: size === "small",
    })}
  >
    <Icon name="chevron-20" mix={styles.icon} />
  </Touchable>
);
