import { useEffect, useState } from 'react';

export const useFluidPaddings = (): number => {
	const [padding, setPadding] = useState(0);

	const resize = (): void => {
		const container = document.querySelector('.container');

		if (container) {
			const { width } = container.getBoundingClientRect();
			setPadding((document.body.getBoundingClientRect().width - width) / 2 + 12);
		}
	};

	useEffect(() => {
		resize();
	}, []);

	useEffect(() => {
		window.addEventListener('resize', resize);
		return () => window.removeEventListener('resize', resize);
	}, []);

	return padding;
};
