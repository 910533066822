import cls from "../Footer/index.module.scss";

const Footer = () => {
  return (
    <footer id="footer" className={cls.footer}>
      <div className={cls.footer__copyright} dangerouslySetInnerHTML={{ __html: `Рекламная игра Emall.by №1
Срок проведения с 20.04.2023 по 31.07.2023 (включая период выдачи призов и публикации результатов).
Свидетельство о регистрации №4306 от 13.04.2023 года, выданное Министерством антимонопольного регулирования и торговли Республики Беларусь.
ООО «ЭфСиБи Бел», УНП 193185741` }} />
    </footer>
  );
};
export default Footer;
