import Body from './components/Body';
import Footer from './components/Footer';
import Header from './components/Header';

const App = () => {
  return (
    <>
      <Header/>
      <Body/>
      <Footer/>
    </>
  );
}

export default App;
