import clsx from "clsx";
import { FC } from "react";

interface IContainer {
  className?: string;
  mix?: string;
  children: any;
}

export const Container: FC<IContainer> = ({
  className = "container",
  children,
  mix,
}) => <div className={clsx(className, mix)}>{children}</div>;
