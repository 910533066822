
import { FC } from 'react';
import { useFluidPaddings } from '../../hooks/useFluidPaddings';

interface IProps {
	mix?: string;
	children?: string | JSX.Element | JSX.Element[];
}

export const ContainerFluid: FC<IProps> = ({ children, mix }) => {
	const padding = useFluidPaddings();

	return (
		<div className={mix} style={{ paddingLeft: padding, paddingRight: padding }}>
			{children}
		</div>
	);
};
