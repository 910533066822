/* eslint-disable @typescript-eslint/no-explicit-any */

import React, { ComponentClass, ComponentElement, ComponentProps, FunctionComponent } from 'react';
import ReactDOM from 'react-dom';
import Modal, { ModalProps } from 'react-responsive-modal';
import { scrollToggle } from './scrollToggle';

interface IProps extends ModalProps {
	closeOnRouteChange: boolean;
}

let defaultModalProps: Partial<IProps> = {
	closeOnOverlayClick: true,
	focusTrapped: false,
	closeOnRouteChange: true,
};

type IModalsItem = ModalWrapper | string;

interface IModals {
	list: IModalsItem[];
	add: (item: IModalsItem) => void;
	remove: (item: IModalsItem) => void;
	count: number;
}

export const modals: IModals = {
	list: [],
	add: (item) => {
		modals.list.push(item);
		scrollToggle().disable();
	},
	remove: (item) => {
		modals.list = modals.list.filter((key) => key !== item);
		if (modals.count < 1) scrollToggle().enable();
	},
	get count() {
		return modals.list.length;
	},
};

class ModalWrapper extends React.Component<{
	div: HTMLDivElement;
	component: ComponentElement<any, any>;
	resolve(payload?: any): void;
	reject(payload?: any): void;
	close(): void;
	modalProps: IProps;
}> {
	top = 0;

	component: ComponentElement<any, any>;

	state = {
		open: true,
	};

	constructor(props: ComponentProps<any>) {
		super(props);

		this.component = React.cloneElement(this.props.component, {
			resolve: this.resolve.bind(this),
			close: this.close.bind(this),
			reject: this.props.reject,
		});
	}
	//
	// closeOnBackButton = (): void => {
	// 	this.resolve(null);
	// };

	onHashChange = (path: string): void => {
		const opened = path.includes('#modal-opened');

		setTimeout(() => {
			if (!opened) this.close();
		}, 100);
	};

	componentDidMount(): void {
		modals.add(this);
	}

	resolve(payload: any): void {
		modals.remove(this);

		this.setState({ open: false }, () => {
			this.props.resolve(payload);

			if (this.props.div && this.props.div.parentNode) {
				this.props.div.parentNode.removeChild(this.props.div);
			}
		});
	}

	// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
	close() {
		// if (modals.count < 2) {
		// 	router.events.off('hashChangeComplete', this.onHashChange);
		// 	router.back();
		// }
		if (this.props.modalProps.onClose) {
			this.props.modalProps.onClose();
		}
		return this.resolve(null);
	}

	onRouteChange = (_: string, { shallow }: { shallow: boolean }): void => {
		if (shallow) return;

		if (this.props.modalProps.closeOnRouteChange) {
			this.resolve(null);
		}
	};

	render(): React.ReactElement {
		return React.createElement(Modal, {
			...{
				open: this.state.open,
				children: this.component,
			},
			...(this.props.modalProps || {}),
			onClose: this.close.bind(this),
		});
	}
}

export const asyncModal = (
	component: ComponentClass<any> | FunctionComponent<any>,
	props: ComponentProps<typeof component> = {},
	modalProps?: Partial<IProps>
): Promise<any | null> => {
	return new Promise((resolve, reject) => {
		const div = document.createElement('div');
		document.body.appendChild(div);
		const wrapper = React.createElement(ModalWrapper, {
			div,
			resolve,
			reject,
			component: React.createElement(component, props),
			modalProps: {
				...defaultModalProps,
				blockScroll: false,
				...(modalProps || {}),
			},
		});

		ReactDOM.render(wrapper, div);
	});
};

asyncModal.setDefaultModalProps = (modalProps: Partial<IProps>) => {
	defaultModalProps = modalProps;
};
