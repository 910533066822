type TScrollToggle = () => {
	enable: () => void;
	disable: () => void;
};

let disabled = false;
let position = 0;

export const scrollToggle: TScrollToggle = () => {
	const getScrollbarWidth = (): number => {
		const outer = document.createElement('div');
		outer.style.visibility = 'hidden';
		outer.style.overflow = 'scroll';
		// @ts-ignore
		outer.style.msOverflowStyle = 'scrollbar'; // needed for WinJS apps
		document.body.appendChild(outer);
		const inner = document.createElement('div');
		outer.appendChild(inner);
		const scrollbarWidth = outer.getBoundingClientRect().width - inner.getBoundingClientRect().width;
		if (outer.parentNode) outer.parentNode.removeChild(outer);
		return scrollbarWidth;
	};

	const root: HTMLDivElement | null = document.querySelector(':root');
	const element = document.body;

	const isMobile = (): boolean => {
		return window.innerWidth < 992;
	};

	const disable = (): void => {
		if (disabled) return;

		position = window.pageYOffset;

		if (root) {
			root.style.setProperty('--scroll-width', `${getScrollbarWidth()}px`);
		}

		if (element) {
			element.style.overflow = 'hidden';

			if (isMobile()) {
				// element.style.position = 'fixed';
				element.style.top = '0px';
				element.style.bottom = '0px';
				element.style.width = '100%';
			}
		}

		disabled = true;
	};

	const enable = (): void => {
		if (!disabled) return;

		if (root) {
			root.style.setProperty('--scroll-width', `0px`);
		}

		if (element) {
			element.style.removeProperty('overflow');

			if (isMobile()) {
				element.style.removeProperty('position');
				element.style.removeProperty('top');
				element.style.removeProperty('width');
				element.style.removeProperty('bottom');
				element.style.removeProperty('min-width');
			}
		}

		if (isMobile()) {
			window.scrollTo(0, position);
		}

		disabled = false;
	};

	return {
		enable,
		disable,
	};
};
