import classNames from "classnames";
import moment from "moment";
import React, { Component, FC, Fragment, ReactElement } from "react";
import { asyncModal } from "../../../helpers/asyncModal";
import { IList } from "../../Body";
import { Modal } from "../../modalComponents";

import styles from "./index.module.scss";

interface IFieldModal {
  close: () => void;
  title: string;
  list: IList[];
  idx: number;
}

interface IState {
  activeIdx: number;
  date?: string;
  active?: boolean;
}

interface ICard {
  name: string;
  info: string;
}

const Card: FC<ICard> = ({ name, info }) => {
  return (
    <div className={styles.card}>
      <span className={styles.card__name}>{name}</span>
      <span className={styles.card__info}>{info}</span>
    </div>
  );
};

class FieldModal extends Component<IFieldModal, IState> {
  constructor(props: IFieldModal) {
    super(props);
    this.state = {
      activeIdx: this.props.idx,
    };
  }
  listHeight = 260;
  fieldRef = React.createRef<HTMLDivElement>();

  apply = (): void => {
    this.props.close();
  };

  scrollTo = (id: number) => {
    const element = document.getElementById(`filter_${id}`);
    if (element) {
      element.scrollIntoView({ behavior: "smooth", inline: "center" });
    }
  };

  componentDidMount() {
    this.scrollTo(this.state.activeIdx);
  }

  render(): ReactElement {
    const { close, list } = this.props;
    return (
      <Fragment>
        <Modal.Swiper onSwipeBottom={close} />
        <Modal.Close onClose={close}/>
        <h3 className={styles.title}>Розыгрыши и победители</h3>

        <div className={styles.field} ref={this.fieldRef}>
          <div className={styles.tabs}>
            {list.map((el, idx) => (
              <div
                key={idx}
                id={`filter_${idx + 1}`}
                onClick={() =>
                  this.setState({ activeIdx: idx + 1 }, () => {
                    this.scrollTo(idx + 1);
                  })
                }
                className={classNames(
                  styles.tabs__tab,
                  this.state.activeIdx === idx + 1 && styles.tabs__tab__active
                )}
              >
                <span>{moment(el.DrawDate).format("DD.MM.YY")}</span>
              </div>
            ))}
          </div>
          <div className={styles.wrapper}>
            {list[this.state.activeIdx - 1].Winners.length ? (
              <>
                {list[this.state.activeIdx - 1].Winners.filter(
                  (el) => el.PrizeType === 6
                ).map((el, idx) => (
                  <>
                    {idx === 0 && (
                      <span className={styles.titleText}>Денежные призы</span>
                    )}
                    <Card name={el.Winner} info={el.GamePrizeDescription} />
                  </>
                ))}
                {list[this.state.activeIdx - 1].Winners.filter(
                  (el) => el.PrizeType === 1
                ).map((el, idx) => (
                  <>
                    {idx === 0 && (
                      <span className={styles.titleText}>Сертификаты</span>
                    )}
                    <Card name={el.Winner} info={el.GamePrizeDescription} />
                  </>
                ))}
              </>
            ) : (
              <span className={styles.wrapper__null}>
               {this.state.activeIdx !== list.length && 'Ожидается розыгрыш 30 сертификатов на 100 BYN и 2 денежных призов по 2 000 BYN'}{" "}
                {this.state.activeIdx === list.length &&
                  "2 денежных приза по 30 000 BYN"}
              </span>
            )}
          </div>
        </div>

        {/* <div className={styles.footer}>
          <Button
            htmlType="button"
            onClick={this.apply}
            block
            type="primary"
            size="large"
            mix={styles.footer__button}
          >
            Подтвердить
          </Button>
        </div> */}
      </Fragment>
    );
  }
}

export const openFieldModal = (list: IList[], idx: number): Promise<void> =>
  asyncModal(
    FieldModal,
    { list, idx },
    {
      closeOnRouteChange: false,
      classNames: {
        modal: classNames("swiping", styles.modal_field),
      },
      showCloseIcon: false,
      focusTrapped: false,
    }
  );
