
export const config = {
	production: process.env.NEXT_PUBLIC_API_IS_PRODUCTION,
	api: {
		cms: {
			domain: process.env.NEXT_PUBLIC_API_CMS_DOMAIN || 'https://test.api.static.edostavka.by',
			cert: process.env.NEXT_PUBLIC_API_CMS_CERT || 'https://test.api.static.edostavka.by',
		},
		market: {
			domain: process.env.NEXT_PUBLIC_API_MARKET_API_URL || 'https://rest.eurotorg.by',
			domainProd: process.env.NEXT_PUBLIC_API_MARKET_API_URL || 'https://rest.eurotorg.by/10306/Json',
			serviceNumberProd: process.env.NEXT_PUBLIC_API_SERVICE_NUMBER || 'C3546D1F-311F-4A6B-BFA5-DD52CDEEB373',
			serviceNumber: process.env.NEXT_PUBLIC_API_SERVICE_NUMBER || '99D193F4-1084-44DD-A629-3BBE6DBDE4FF',
			port: process.env.NEXT_PUBLIC_API_IS_PRODUCTION ? null : process.env.NEXT_PUBLIC_API_MARKET_API_PORT || 10399,
			webDirection: process.env.NEXT_PUBLIC_API_WEB_DIRECTION || 64,
		},
		logger: {
			domain: 'https://logs.edostavka.by',
			path: '/api/front_emalls',
			bot_path: '/api/bot_logs',
			source: 'emall',
		},
	},
	salt: {
		payment: process.env.SALT_PAYMENT || 'TODO',
	},
	reCaptchaKey:
		process.env.NEXT_PUBLIC_API_CAPTCHA_KEY ||
		(process.env.NEXT_PUBLIC_API_IS_PRODUCTION
			? '6LftwUEjAAAAAKYofkQLWyUNyxh62QgZqpR2OLdt'
			: '6LcSyqkZAAAAAO6rQlIXlKqQAo6QhE7eFdq0VWda'),
	cookieMaxAge: 2592000, // 60 * 60 * 24 * 30 --- 30 дней,
	productPacketId: 1720706, // ID товара пакета
	gtm: { id: process.env.NEXT_PUBLIC_GTM_ID ?? 'GTM-WZRXC4T' },
};

export const CART_REQUEST_TIMEOUT = 1000;
